import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp,faInstagram,faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import logo from "./assets/logo.png"
const MenuBar = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
    <Container  style={{textAlign:'center', marginLeft: isMobile ? '0%':'10%'}}>
      <Navbar.Brand href="#"><img src={logo}  style={{paddingRight:'0px',width:isMobile ?"65%":"90%", height:"80%", borderRadius:20, marginLeft: isMobile ?'-80px':0}} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll"  />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '200px' ,marginLeft: isMobile ? '0%':'10%', }}
          navbarScroll
        >
          <Nav.Link href="#action1" className='navitem'  >Home</Nav.Link>
          <Nav.Link href="#aboutus" className='navitem'>About Us</Nav.Link>
          <Nav.Link href="#work" className='navitem'>How we work</Nav.Link>
          <Nav.Link href="#contactus" className='navitem'>Contact Us</Nav.Link>
          <Nav.Link href="https://chat.whatsapp.com/IEsAEk7UWKeBsA8P7X4Qkc" target='_blank' className='navitem1'><FontAwesomeIcon icon={faWhatsapp}  size="2x"/></Nav.Link>
          <Nav.Link href="https://www.instagram.com/det_toastmastersclub/?hl=en" target='_blank' className='navitem1'><FontAwesomeIcon icon={faInstagram} size="2x"/></Nav.Link>
          <Nav.Link href="https://www.facebook.com/people/Deshpande-Educational-Trust-Toastmasters-Club-Hubli-Karnataka-India/100054329118717/" target='_blank' className='navitem1'><FontAwesomeIcon icon={faFacebook} size="2x"/></Nav.Link>
          <Nav.Link href="https://www.google.com/maps/place/Deshpande+Foundation/@15.3710439,75.1235492,15z/data=!4m6!3m5!1s0x3bb8d0ccc2b533df:0x81863b82ace508e1!8m2!3d15.3706867!4d75.1223388!16s%2Fg%2F11xs0c2mt?entry=ttu" target='_blank' className='navitem1s'>  <FontAwesomeIcon icon={faLocationDot} size="2x"/></Nav.Link>
          {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="#" disabled>
            Link
          </Nav.Link> */}
        </Nav>
        {/* <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          />
          <Button variant="outline-success">Search</Button>
        </Form> */}
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
};

export default MenuBar;
