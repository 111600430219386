import '../App.css';
import v1 from "../assets/v1.png";
import v2 from "../assets/v2.png";
import v3 from "../assets/v3.png";
import v4 from "../assets/v4.png";
import v5 from "../assets/v5.png";
function Session(props) {
  const isMobile = window.innerWidth <= 768;
  if(isMobile){
    return (
      <div className='row' style={props.style} >
      
      <div className='col-md-5'>
        <div className='box'> <img src={props.path}  style={{paddingRight:'0px',width:"100%", height:"100%", borderRadius:20,position:'relative'}} />  {(props.index == 1 || props.index == 3 || props.index == 5)  ?  <img src={props.npath}  className='n2' /> : <img src={props.npath}  className='n1' />}   </div>
      </div>
      <div className='col-md-7'>
        <p className='boxtitle' style={{whiteSpace:'nowrap'}} ><strong>{props.title}</strong></p>
        <p className='boxtitle'>{props.role}</p>
        <p className='boxp'>{props.description}</p><b><i>{props.italic}</i></b>
      </div>
    </div>
  )
  }else{
    return (
      <div className='row' style={props.style} >
        <div className='col-md-1 '>
          <div className='line1'>
            { props.index == 1 && (<img src={v1} className='v1' />)}
            { props.index == 2 && (<img src={v2} className='v2'   />)}
            { props.index == 3 && (<img src={v3} className='v3'  />)}
            { props.index == 4 && (<img src={v4} className='v4' />)}
            { props.index == 5 && (<img src={v5} className='v5'  />)}
           
         
          </div>
        </div>
      <div className='col-md-4'>
        <div className='box'> <img src={props.path}  style={{paddingRight:'0px',width:"100%", height:"100%", borderRadius:20,position:'relative'}} />  {(props.index == 1 || props.index == 3 || props.index == 5)  ?  <img src={props.npath}  className='n2' /> : <img src={props.npath}  className='n1' />}   </div>
      </div>
      <div className='col-md-7'>
        <p className='boxtitle' style={{whiteSpace:'nowrap'}} ><strong>{props.title}</strong></p>
        <p className='boxtitle'>{props.role} </p>
        <p className='boxp'>{props.description} <b> <i>{props.italic}</i> </b> </p>
      </div>
    </div>
  )
  }
    

}

export default Session;