import '../App.css';

function Testimonals(props) {
 
    return (
      
        <div className='videodiv' style={props.style}>
          <div className='im'>
          <iframe
      width='100%'
      height='100%'
      src={props.link}
      title='YouTube Video Player'
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowfullscreen
    ></iframe>

          </div>
          <div className='videocontent'>
            <p className='csize'>{props.title}</p>
            <p className='dsize'>"{props.description}"</p>
            <p className='nsize'>{props.name}</p>
          </div>
        </div>
      
    )

}

export default Testimonals;