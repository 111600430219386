import logo from './logo.svg';
import './App.css';
import MenuBar from './Menubar';
import Session from './components/session';
import Testimonals from './components/testimonals';
import Bearers from './components/bearers';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Gallery } from "react-grid-gallery";
import heroim from './assets/hero.png';
import saa from './assets/saa.png';
import precident from './assets/precident.png';
import ttm from './assets/ttm.png';
import ge from './assets/ge.png';
import mentor from './assets/mentors.png';
import tmod from './assets/tmod.png';
import p from './assets/p.png';
import vpe from './assets/vpe.png';
import vpm from './assets/vpm.png';
import vpr from './assets/vpr.png';
import s from './assets/s.png';
import sa from './assets/sa.png';
import t from './assets/t.png';
import ipp from './assets/ipp.png';
import i1 from './assets/g1.png';
import i2 from './assets/g2.png';
import i3 from './assets/g3.png';
import i4 from './assets/g4.png';
import i5 from './assets/g5.png';
import i6 from './assets/g6.png';
import logo1 from "./assets/logo.png";
import logo2 from "./assets/t-logo.png";
import Form from 'react-bootstrap/Form';
import facebook from './assets/facebook.png';
import whatsapp from './assets/whatsapp.png';
import instagram from './assets/instagram.png';
import call from './assets/call.png';
import location from './assets/Location.png';
import GoogleMapReact from 'google-map-react';
import PhotoAlbum from "react-photo-album";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import date from './utils/date'
import TestimonialData from './data/testimonial';
import Nav from 'react-bootstrap/Nav';


const mapReactComponent = ({ text }) => <div>{text}</div>;


const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
  position: { lat: 15.370711908550632, lng: 75.12234033891575},
  map,
  title: 'Hello World!'
  });
  return marker;
 };

 const testimonalsCards = TestimonialData.map(testimonial =>{
  return <Testimonals   title={testimonial.title} description={testimonial.description} name={testimonial.name} link = {testimonial.link}></Testimonals>
 });

function App() {
  const defaultProps = {
    center: {
      lat: 15.370711908550632,   
      
      lng: 75.12234033891575
    },
    zoom: 15
  };

  const isMobile = window.innerWidth <= 768;

  const images = [
    {
      src: require("./assets/1.png"),
      width: 620,
      height: 540,
     
   },
   {
    src: require("./assets/2.png"),
    width: 550,
    height: 412,
   
 },
 {
  src: require("./assets/3.png"),
  width: 620,
  height: 403,
 
},
{
  src: require("./assets/4.png"),
  width: 550,
  height: 532,
 
},
{
  src: require("./assets/5.png"),
  width: 692,
  height: 532,
 
},
{
  src: require("./assets/6.png"),
  width: 476,
  height: 530,
 
},

 



 ];
  return (
    <div className="App">
      <MenuBar/>
      <div id="home">
      <section style={{padding:'0px'}}>
        <div className='row' style={{paddingBottom:50,   padding: isMobile ? 30 : '50px 100px 50px 100px'}}>
          <div className='col-md-6' style={{marginTop:isMobile ?10:50}}>
            <div style={{textAlign:'left'}}><p style={{fontSize : isMobile?40:56}}></p><p style={{fontSize : isMobile?40:50}}>We toast to confidence and the mastery of the art of speaking, communication,<br></br> and leadership.</p></div>
            <div style={{marginTop:50,textAlign:'left'}}><p style={{fontSize:22}}>15,200 clubs accross the globe, platform to improve your communication skills</p></div>
            <div style={{marginTop:50}}>
              <div className='row' style={{textAlign:'left'}}>
              <div className='col-md-6'>
                <div >
                  <a href='https://chat.whatsapp.com/IEsAEk7UWKeBsA8P7X4Qkc' target='_blank'>
                    <button className='btn btn-lg' style={{backgroundColor:'#004165', color:'white', width:240, borderRadius:5, borderWidth:1, marginBottom: isMobile ? 30 : 0}}>Join Community</button>
                  </a>
                </div>
              </div>
              <div className='col-md-6'>
              <div>
                <a href='https://wa.me/+918310999502' target='_blank'>
                  <button className='btn btn-lg' style={{backgroundColor:'white',borderRadius:5, borderWidth:1, width:220, borderColor:'black'}}>Contact Us</button>
                </a>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'><img src={heroim} alt="Logo" className='mhero' style={{paddingRight:'10px',    width:'100%'}} /></div>
        </div>
        <div className='row' style={{paddingTop:50}}>
        <div className='col-md-4' >
          <div className='sepdiv3'>
          {/* <p style={{fontSize:isMobile ?24:48}}>| What??</p> */}
          <p style={{lineHeight:1.6, fontSize:isMobile ?16:24}}>
          <p style={{fontSize:isMobile ?24:48}}>| What?</p>
          Attend meetings every thursday to learn the art of communication and become a champion 
          </p>
          </div>
        </div>
        <div className='col-md-4' >
          <div className='sepdiv1' >
           <p style={{fontSize:isMobile ?24:48}}>| When?</p>
           <p style={{fontSize:isMobile ?16:24}}>
             <strong> Day:</strong> Every Thursday
            </p>
           <p style={{fontSize:isMobile ?16:24}}>
            <strong>  Upcoming:</strong> {date()} 
           </p>
            <p style={{fontSize:isMobile ?16:24}}> 
             <strong>Time:</strong>  06:10pm to 8:10pm
           </p>
           
           </div>
        </div>
        <div className='col-md-4' >
          <div className='sepdiv2' >
          <p style={{fontSize:isMobile ?24:48}}>| Where?</p>
           <p style={{fontSize:isMobile ?16:24}}>
            <strong>  Location:</strong> Seminar Hall, Deshpande Foundation, BVB Campus, Vidya Nagar, Hubballi, Karnataka 580031



           </p>
           {/* <p style={{fontSize:24}}>
             <strong> Day:</strong> Every Thursday
            </p>
            <p style={{fontSize:24}}> 
             <strong>Time:</strong>  06:00pm to 8:15pm
           </p> */}
        </div>
        </div>
        </div>
      
      <div className='div1' id="aboutus">
        <div className='middiv'>
          <p style={{fontSize:isMobile ? 38: 68}}>About Us</p>
          <p style={{marginLeft:isMobile ?0:100, marginRight:isMobile ? 0:100,paddingTop:0, fontSize:isMobile ?12: 32,color:'#959595'}}>Toastmasters International and Deshpande Educational Trust Toastmasters</p>
          <div className='row' style={{paddingLeft:isMobile ? 0:50, paddingRight:isMobile ? 0: 50, marginTop:30}}>
            <div className='col-md-5'>
             <div style={{width:"85%", height:"100%", borderRadius:20,marginLeft: isMobile ? 18 : 0}}>
             <img src={require("./assets/smedley.jpeg")} alt="Logo" style={{paddingRight:'0px',width:"100%", height:"100%", borderRadius:20, }} />
             </div>
            </div>
            <div className='col-md-7' style={{paddingLeft:isMobile ?10: 30,paddingTop:20}}>
              <div className='titlediv'><p className='title'>TOASTMASTERS INTERNATIONAL</p></div>
              <div><p className='title2'>Founded in the year 1924</p><p className='title2'>by Ralph C. Smedley</p>
                       </div>
              <div style={{marginTop:10}}>
                <p style={{textAlign: 'justify', fontSize:isMobile ?14:22, color:'#959595'}}>The educationalist who believed in the importance of interpersonnel skills, from communication and leadership to management and networking, founded  Toastmasters International which developed into a cluster of 16,400 clubs in 141 countries.</p>
              </div>
              {/* <div style={{textAlign:'left'}}><button className='btn' style={{fontSize:16, backgroundColor:'#F2DF74'}}>Read More</button></div> */}
            </div>
          </div>
          <div className='row' style={{paddingLeft:isMobile ? 0 :50, paddingRight:isMobile ? 0: 50, marginTop:30}}>
            <div className='col-md-5'>
             <div style={{marginLeft: isMobile ? 18 : 0,width:"85%", height:"100%", borderRadius:20}}>
             <img src={require("./assets/first.png")} alt="Logo" style={{paddingRight:'0px',width:"100%", height:"100%", borderRadius:20, }} />
             </div>
            </div>
            <div className='col-md-7' style={{paddingLeft:isMobile ?10: 30, paddingTop:20}}>
              <div className='titlediv'><p className='title'>DET TOASTMASTERS CLUB
            </p></div>
              <div><p className='title2'>Founded in the year 2014 </p><p className='title2'>by three friends at Hubli</p>
                       </div>
              <div style={{marginTop:10}}>
                <p style={{textAlign: 'justify',fontSize:isMobile ?14:22,color:'#959595'}}>The essence of Toastmasters International transcended to Hubli when three friends, TM Veernarayan Kulkarni, TM Sanjay Kulkarni and TM Manjunath Hiremath founded Deshpande Educational Trust Toastmasters club. Their vision was to provide an international platform for everyone, from students to entrepreneurs in Hubli-Dharwad district.</p>
              </div>
              {/* <div style={{textAlign:'left'}}><button className='btn' style={{fontSize:16, backgroundColor:'#F2DF74'}}>Read More</button></div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='div2' id="work">
        <p className='p1'>A glimpse into our <b>impactful</b> meetings</p>
        <p style={{fontSize:isMobile ?12:22, color:'#959595'}}>Our meetings aim to have impactful footprints in the minds of learners and speakers through carefully curated sessions.</p>
        <div className='third'>
        <Session title="Sergeant At Arms:" italic="A smile that greets you welcome." description="SAA initiates the meeting with the enthusiasm, energy, and ethics of toastmasters international which lay a foundation for the meeting decorum, order, and flow."  role="Setting the tone" path={saa} npath={i1} index = "0" style={{marginLeft:isMobile ?5:0}}/>
      

       <Session title="The Presidential Address:" italic="Words that enlighten the mind" description="The president is the head of the club who addresses the meeting with a passion to serve the members and supporting members by motivating them to guide the path to achieving their goals individually as well as a club."  role="Guiding us through"  style={{marginLeft:isMobile ?25:150}} path={precident} index = "1" npath={i2} />

       <Session title="Toastmaster of the Day:" italic="A liaison that binds your journey." description="The Toastmaster of the day guides us through the journey of sessions of the meeting and makes sure it stays organized and flows smoothly."  role="Wagon of the Journey" path={tmod} index = "2" npath={i3} style={{marginLeft:isMobile ?5:0}}/>

       <Session style={{marginLeft:isMobile ?25:150}} title="Table Topics:" italic="Unloading thoughts the creative way." description="The thrilling session of impromptu topics that keeps the Toastmasters and guests on their toes is hosted by the table topic master, who pitches impromptu topics to participants. This session demands swift answers that create an image of thoughts."  role="Realm of swift thoughts" path={ttm} index = "3" npath={i4}/>

       <Session title="General Evaluation:" italic="Always learning to learn." description="A general evaluation is a detailed assessment of every aspect of the meeting conducted. This is presented by the general evaluator supported by the TAGL team ie Timer, Ah-counter, Grammarian, and Listener. This session provides us with points of excellence, improvements, and pieces of advice. Because we at DET believe that feedback is the breakfast of champions."  role="Rewinding with feedback" path={ge} index = "4" npath={i5} style={{marginLeft:isMobile ?5:0}}/>

       <Session style={{marginLeft:isMobile ?25:150}} title="Networking:" italic="Forming purposeful ties" description="This session provides an opportunity to know speakers from students to entrepreneurs, a wide spectrum of fields with various experiences. They bring in varied perspectives through which learning is influenced in a positive direction."  role="Elevating with connections" path={mentor} index = "5" npath={i6}/>
      
       
       
        </div>
      </div>

      <div className='div3' id="testimonals">
        <p style={{fontSize:isMobile ?16:40, color:'#E2E2E2' , textAlign:'left'}}>TESTIMONALS</p>
        <p style={{fontSize:isMobile ?22:62, color:'#FFFFFF' , textAlign:'left'}}>What experience do we at DET Toastmasters offer? Hear from those who have experienced it.</p>
        <div>
        {isMobile && (
       <div>
        <Carousel
      showThumbs={true}
      showStatus={true}
      showIndicators={true}
      infiniteLoop
      swipeable
      emulateTouch
      dynamicHeight
      autoPlay
      interval={5000}
    >
        {/* <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals>
        <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals>
        <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals>
        <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals>

            <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals>

            <Testimonals   title="Student" description="“ A Engineering student who won area level contest in public speaking ”" name="Name"></Testimonals> */}
            {testimonalsCards}
    </Carousel>
    {/* <button type="button" aria-label="next slide / item" class="arrow right control-arrow control-next"></button> */}
    </div>
     )}
      {!isMobile && (
          <div className='row'>
            <div className='col-6'>
              {testimonalsCards.slice(0,3)}
            </div>
            <div className='col-6' style={{marginTop:'45px'}}>
              {testimonalsCards.slice(3)}
            </div>
          </div>
          )}
        </div>
      </div>

      <div className='div4' id="office-bearers">
        <p style={{fontSize:isMobile ?24:54,color:'#414141', textAlign:'center'}}>Current Office Bearers
      </p>
      <p style={{fontSize:isMobile ?12:32, color:'#7D7F8D'}}>Meet the remarkable individuals who, as office bearers, are at the helm, leading the club to new heights of success and camaraderie.</p>
      
      <div className='row'>
      <Bearers  name="Manoj Banvasi" position="President" path={p}> </Bearers>

      <Bearers  name="Pramod Dattawad" position="VP-Education" path={vpe}></Bearers>

      <Bearers  name="Ruchita Amingad" position="VP-Membership" path={vpm}></Bearers>

      <Bearers  name="Nitish Gedageri" position="VP-Public Relation" path={vpr}></Bearers>

      <Bearers  name="Ashika  M.V" position="Secretary" path={s}></Bearers>

      <Bearers  name="Srujana Kappali" position="Treasurer" path={t}></Bearers>

      <Bearers  name="Pranav Amingad" position="Sergeant-At-Arms" path={sa}></Bearers>

      <Bearers  name="Siri Tarlagatti" position="Immediate Past President" path={ipp}></Bearers>
     
      </div>
     
      </div>
      <div className='div5' id="gallery">
        <div><p style={{fontSize:isMobile ? 38 :54, color:'white'}}>Events and Gallery 
 </p></div>
        <div style={{paddingBottom:50}}><p style={{fontSize:32,color:'white'}}>Explore the vibrant tapestry of DET's events and bask in the radiant achievements of its exceptional members.</p></div>
        <div className='grid-wrapp'>
        {/* <Gallery images={images} /> */}
        <PhotoAlbum layout={isMobile ? "columns" : "rows"} columns={isMobile ? 1 : ''} photos={images} />;

        </div>
        
      </div>

      <div className='div6' id="contactus">
        <div className='row'>
          <div className='col-md-8'>
            <div style={{textAlign:'left'}}><img src={logo1}  style={{paddingRight:'0px',width:isMobile ?"40%":"30%", height:"100%", borderRadius:20, marginLeft: isMobile ?'0px':0}} /></div>
            <div style={{marginTop:20, marginLeft:isMobile ? 0:'-8%', marginRight:isMobile ?20:0}}>  <div className='row'>
            <div className='col-3'><Nav.Link href='#home' className='fmeanu'>Home</Nav.Link></div>
            <div className='col-3'><Nav.Link href='#aboutus' className='fmeanu'>About Us</Nav.Link></div>
            <div className='col-3'><Nav.Link  href='#work'  className='fmeanu'>How we work</Nav.Link></div>
            <div className='col-3'><Nav.Link href='#contactus'  className='fmeanu'>Contact Us</Nav.Link></div>
            </div></div>
          <div>
            <div className='row' style={{marginTop:20}}> 
              <div className='col-md-6'>
              <div style={{ height: '40vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        <mapReactComponent
          lat={15.370711908550632}
          lng={75.12234033891575}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
              </div>
              <div className='col-md-6' style={{textAlign:'left', marginTop:20}}>
              <div style={{marginBottom:30, marginTop:20}}>   <a href='tel: +918310999502' style={{textDecoration:'none', color:"#212529"}}><img src={call} alt="Logo" style={{paddingRight:'10px',width:40}} />+91 831 099 9502</a></div>
                <div> <a href='https://www.google.com/maps/place/Deshpande+Foundation/@15.3710439,75.1235492,15z/data=!4m6!3m5!1s0x3bb8d0ccc2b533df:0x81863b82ace508e1!8m2!3d15.3706867!4d75.1223388!16s%2Fg%2F11xs0c2mt?entry=ttu' target = '_blank' style={{textDecoration:'none', color:"#212529"}}><img src={location} alt="Logo" style={{paddingRight:'10px', width:40}} />Seminar Hall, Deshpande Foundation, BVB Campus, Vidya Nagar, Hubballi, Karnataka 580031</a></div>
              
               
                <div className='row' style={{marginTop:30}}>
                <div className='col-4'><a href='https://chat.whatsapp.com/IEsAEk7UWKeBsA8P7X4Qkc' target='_blank'><img src={whatsapp} alt="Logo" style={{paddingRight:'10px', width:60}} /></a></div>
                <div className='col-4'><a href='https://www.instagram.com/det_toastmastersclub/?hl=en' target='_blank'><img src={instagram} alt="Logo" style={{paddingRight:'10px',width:60}} /></a></div>
                <div className='col-4'><a href='https://www.facebook.com/people/Deshpande-Educational-Trust-Toastmasters-Club-Hubli-Karnataka-India/100054329118717/' target='_blank'><img src={facebook} alt="Logo" style={{paddingRight:'10px',width:60}} /></a></div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className='col-md-4'>
            <div className='cform' >
    <Form action='”mailto:01fe20bcs260@kletech.ac.in' method='POST' enctype='text/plain' name = "EmailForm" >
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="name" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="Contact number">
        <Form.Label>Contact Number</Form.Label>
        <Form.Control type="Tel" placeholder="Enter Contact Number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      </Form.Group>
      <button className='btn' style={{backgroundColor:'#004165', color:'white',textAlign:'center', margin:'0'}} type='submit' value='Send'>Submit</button>
    </Form>
            </div>
   
          </div>
        </div>
      </div>

      </section>
      </div>
      <FloatingWhatsApp
        phoneNumber="+918310999502"
        accountName="Vice President Membership"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={logo2}
      />
      <div className="footer-copyright text-center py-3" style={{backgroundColor:'#F2DF74'}}>
        <a href="#home" style={{textDecoration:'none', color:'black'}}> © 2023 Deshpande educational trust toastmasters Club   | Privacy policy. - Developed by DET Toastmasters Club</a>
    </div>
    </div>
  );
}

export default App;
