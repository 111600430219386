import '../App.css';
const isMobile = window.innerWidth <= 768;
function Bearers(props) {
  if(isMobile){
    return (


      <div className='col-6'>
      <div className='office'>
      <div className='officeim'>
      <img src={props.path}  style={{paddingRight:'0px',width:"100%", height:"90%", borderRadius:20}} />
        </div>
        <div>
          <p className='officename'>{props.name}  </p>
          <p className='position'>{props.position}</p>
        </div>
      </div>
    </div>
    
    
         
        )
  }else{
    return (


      <div className='col-3'>
      <div className='office'>
      <div className='officeim'>
      <img src={props.path}  style={{paddingRight:'0px',width:"100%", height:"90%", borderRadius:20}} />
        </div>
        <div>
          <p className='officename'>{props.name}  </p>
          <p className='position'>{props.position}</p>
        </div>
      </div>
    </div>
    
    
         
        )
  }
  

}

export default Bearers;