export default function date(){

  const today = new Date();
  today.setHours(7);
  today.setMinutes(10);

  let currentDayOfWeek = today.getDay();
  //console.log(currentDayOfWeek)


   let daysUntilThursday = (4 - currentDayOfWeek + 7) % 7;

   if(currentDayOfWeek == 4 && today.getHours() >= 7) daysUntilThursday += 7
   
  

  const nextThursday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysUntilThursday);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let month = months[nextThursday.getMonth()];



   const date = nextThursday.getDate();

   let suffix

   if(date == 1) suffix = "st"
   else if(date == 2) suffix = "nd";
   else if(date == 3) suffix = "rd"
   else suffix = "th"

   const dateStr = `${date}${suffix} ${month} ${nextThursday.getFullYear()}`

   console.log(dateStr)

   return dateStr
}


