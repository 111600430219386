export default [
    {
        link : 'https://www.youtube.com/embed/lbgfKlNKlUs',
        title : 'Founding President of DET',
        description: 'I got the oppurtunity to meet people and make friends from across the world',
        name: 'TM Veernarayan Kulkarni'
    },
    {
        link : 'https://www.youtube.com/embed/SXJFejsNLvE',
        title : 'Evaluation Speech Contest Champion 2021',
        description: 'DET Toastmasters allowed me to figure out my leadership and communication style',
        name: 'TM Preeti Kothari'
    },
    {
        link : 'https://www.youtube.com/embed/3IHZm36ndDI',
        title : 'Humanities Student',
        description: 'Toastmasters has helped me in excelling myself and has introduced me to fantastic individuals!',
        name: 'TM Dharshita Raikar'
    },
    
    {
        link : 'https://www.youtube.com/embed/wiuVF6ALn_A',
        title : 'Engineering Student',
        description: 'DET Toastmasters allowed me to figure out my leadership and communication style',
        name: 'TM Sitanshu Hallad'
    },
    {
        link : 'https://www.youtube.com/embed/54RKRtuyCtI',
        title : 'Choclatier',
        description: 'DET Toastmasters allowed me to communicate more effectively with my clients and in my day to day conversations',
        name: 'TM Anand Kustagi'
    },
    {
        link : 'https://www.youtube.com/embed/09jzugev4zw',
        title : 'Teaching Professional',
        description: 'DET has helped me better understand people by helping me improve my communication',
        name: 'TM Nikita Swadhi'
    }

]

